import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Actionable Google Home Analytics`}</h1>
    <p>{`Increase user engagement, retention, and monetization through actionable conversational analytics for your Google Assistant Actions.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reports.dashbot.io/signup"
      }}><br parentName="a"></br>
        {`Get Started  `}</a><br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/02/16222743/google-assistant-header.png",
        "alt": null
      }}></img></p>
    <h3>{`Messages Processed`}</h3>
    <figure>![](https://i.dashbot.io/wp-home/uploads/2018/02/02221150/benefits-insights.png)</figure>### Understand Intents
    <p>{`Examine utterances and understand how users interact with your Google Action`}</p>
    <figure>![](https://i.dashbot.io/wp-home/uploads/2018/03/03232524/comparison-scale.png)</figure>### Compare Performance
    <p>{`Compare usage across voice platforms and other Google Actions`}</p>
    <figure>![](https://i.dashbot.io/wp-home/uploads/2018/02/02221151/benefits-tools.png)</figure>### Build a Better Action
    <p>{`Improve AI response effectiveness and conversational flow through Google Assistant analytics`}</p>
    <h1>{`Engagement, Retention, Demographics and more!`}</h1>
    <p>{`Dashbot’s Google Home Analytics help you better understand the users of your Google Assistant Actions`}</p>
    <p><a parentName="p" {...{
        "href": "/tour/engagement"
      }}>{`View sample reports`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/02/03215800/features-analytics-600.png",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/14214636/alexa-intents1.png",
        "alt": null
      }}></img></p>
    <h1>{`Intents and NLP`}</h1>
    <p>{`With Dashbot Google Home Analytics, see how utterances are mapping to Intents`}</p>
    <p>{`Understand how users communicate and identify new use cases`}</p>
    <p><a parentName="p" {...{
        "href": "/tour/conversational-analytics"
      }}>{`View sample reports`}</a></p>
    <h2>{`Interaction Flows`}</h2>
    <p>{`Navigate Google Home Action interaction flows based on Intents and Utterances`}</p>
    <p><a parentName="p" {...{
        "href": "/tour/behavior"
      }}>{`See sample reports`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/14215905/alexa-funnel.png",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/14221557/alexa-transcripts.png",
        "alt": null
      }}></img></p>
    <h1>{`Live Transcripts`}</h1>
    <p>{`View Google Home transcripts in real time with additional NLP meta data`}</p>
    <p>{`See all the Intents, Actions, and Contexts associated with the utterances in your Google Action`}</p>
    <p>{`Everything is archived!`}</p>
    <p><a parentName="p" {...{
        "href": "/tour/conversational-analytics"
      }}>{`See sample reports`}</a></p>
    <h2>{`Multimodal Support`}</h2>
    <p>{`Support for Google Assistant screens`}</p>
    <p>{`View rich messages including SSML, images, cards, lists, and more.`}</p>
    <p><a parentName="p" {...{
        "href": "/tour/conversational-analytics"
      }}>{`See sample reports`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/14222416/alexa-multimodal.png",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/14222936/alexa-behavior.png",
        "alt": null
      }}></img></p>
    <h1>{`Behavior Flows`}</h1>
    <p>{`View the full paths of users`}</p>
    <p>{`Quickly identify drop off and abandonment as well as conversions in your Google Action`}</p>
    <p><a parentName="p" {...{
        "href": "/tour/behavior"
      }}>{`See sample reports`}</a></p>
    <h2>{`Goal Funnels`}</h2>
    <p>{`Track steps and conversions and see how many users are making it through the pipeline in your Google Action`}</p>
    <p><a parentName="p" {...{
        "href": "/tour/behavior"
      }}>{`See sample reports`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/04093105/goal-funnel-trans.png",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/04093104/cross-channel.png",
        "alt": null
      }}></img></p>
    <h1>{`Cross Channel Reports`}</h1>
    <p>{`Have both Alexa Skills and Google Actions?`}</p>
    <p>{`Compare metrics across channel platforms`}</p>
    <p><a parentName="p" {...{
        "href": "/tour/comparison-metrics"
      }}>{`See sample reports`}</a></p>
    <h1>{`Industry Benchmarking`}</h1>
    <p>{`See how your Google Actions compare to the industry based on interaction, user count, and retention`}</p>
    <p><a parentName="p" {...{
        "href": "/tour/comparison-metrics"
      }}>{`See sample reports`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/04093108/market-metrics.png",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/04093106/integration.png",
        "alt": null
      }}></img></p>
    <h1>{`Easy to Integrate`}</h1>
    <p>{`Simply add two or three lines of code to your Google Assistant Action.`}</p>
    <p>{`We integrate with `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/docs/google/firebase/"
      }}>{`Google Firebase`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/docs/google/dialogflow/"
      }}>{`DialogFlow`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/docs/google/action-sdk/"
      }}>{`Actions SDK`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/docs/google/"
      }}>{`more`}</a>{`.`}</p>
    <h1>{`Overview Video`}</h1>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/hTNSvFclivU?feature=oembed" width="1200"></iframe>
    <h1>{`Top Google Actions use Dashbot`}</h1>
    <p><a parentName="p" {...{
        "href": "https://www.aetv.com/"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/03/15084206/aande-150x150.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://www.usanetwork.com/mrrobot"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/03/15084208/mrrobot-150x150.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.hearst.com"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/03/15084207/hearst-150x150.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.grubhub.com"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/02/09103629/grubhub-150x150.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://www.progressive.com"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/03/15084208/progressive-150x150.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.nationalgeographic.com"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/03/15084816/natgeo-150x150.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.earplay.com"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/03/15084206/earplay-150x150.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.groundcontrol.ai"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/03/15084207/groundcontrol-150x150.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://volleythat.com/"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/02/09103631/volley-150x150.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.amazon.com/Ground-Control-Gordon-Ramsay/dp/B0797H5D2X"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/03/15084209/ramsey-150x150.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.starbutter.com"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/03/16085520/starbutter-150x150.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.amazon.com/The-Magic-Door-LLC/dp/B01BMUU6JQ"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/03/15085525/magicdoor-150x150.png",
          "alt": null
        }}></img></a></p>
    <h1><a parentName="h1" {...{
        "href": "/press"
      }}>{`Dashbot in the News`}</a></h1>
    <figure>[![](https://i.dashbot.io/wp-home/uploads/2019/01/20170859/db-pr-200x200.png)](https://www.dashbot.io/2019/01/03/leading-conversational-analytics-platform-dashbot-raises-3-million-round/)</figure>### [Dashbot Raises $3M Lead by Bessemer](https://www.dashbot.io/2019/01/03/leading-conversational-analytics-platform-dashbot-raises-3-million-round/)
    <figure>[![](https://i.dashbot.io/wp-home/uploads/2019/01/20170904/vb-200x200.png)](https://venturebeat.com/2018/11/17/heres-what-people-are-really-doing-with-their-alexa-and-google-home-assistants/)</figure>### [Here’s what people are really doing with their Alexa and Google Home assistants](https://venturebeat.com/2018/11/17/heres-what-people-are-really-doing-with-their-alexa-and-google-home-assistants/)
    <figure>[![](https://i.dashbot.io/wp-home/uploads/2019/01/20170902/medium-200x200.png)](https://medium.com/datadriveninvestor/examining-consumer-purchasing-behavior-through-alexa-and-google-home-c0d97e2e54d0)</figure>### [Examining consumer purchasing behavior through Alexa and Google Home](https://medium.com/datadriveninvestor/examining-consumer-purchasing-behavior-through-alexa-and-google-home-c0d97e2e54d0)
    <figure>[![](https://i.dashbot.io/wp-home/uploads/2019/01/20170858/chatbots-200x200.png)](https://chatbotsmagazine.com/alexa-skill-usage-continues-to-rise-e6988cc700f8)</figure>### [Alexa Skill usage continues to rise](https://chatbotsmagazine.com/alexa-skill-usage-continues-to-rise-e6988cc700f8)
    <h3><a parentName="h3" {...{
        "href": "https://www.dashbot.io/press"
      }}>{`read more`}</a></h3>
    <h1>{`Our Investors`}</h1>
    <p><a parentName="p" {...{
        "href": "http://ffvc.com/"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/02/03224835/ffvc.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://www.bvp.com/"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/02/03224835/bessemer.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://samsungnext.com/"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2019/01/20112749/samsung-next-300x100.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://www.scrum.vc/"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/02/03224832/scrum.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://www.bdmifund.com/"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/02/03224833/bdmi.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://rembrandtvc.com/"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/02/03224833/rembrandt.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://ventures.rga.com/"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/02/03224831/rga.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://recruitstrategicpartners.com/"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/02/03224832/recruit.png",
          "alt": null
        }}></img></a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://www.runacap.com/"
      }}><br parentName="a"></br>
        <img parentName="a" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2019/01/20113632/runa-capital-300x100.png",
          "alt": null
        }}></img></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      